<template>
  <div v-if="selectedCompra">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <comprasForm
        :update="true"
        compraSubmit="Editar OC"
        :compra="selectedCompra"
        @processCompra="updateCompra"
      />
    </b-overlay>
  </div>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const comprasForm = () => import('@/layouts/components/Compras/ComprasForm.vue')

export default {
  components: {
    BOverlay,
    comprasForm,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('compras', ['selectedCompra']),
  },
  methods: {
    ...mapActions({ editarcompras: 'compras/updateCompras' }),
    updateCompra(compra) {
      this.spinner = true
      this.editarcompras(compra).then(() => {
        const errorcompras = store.state.compras
        // const errorMessage = errorcompras.errorMessage.errors
        if (!errorcompras.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Compra editado con éxito 👍',
              text: `El compra "${compra.referencia}" fue editada con éxito!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'compras',
          })
        } else if (errorcompras.error) {
          if (errorcompras.errorMessage === 'stock_negativo') {
            this.$swal({
              title: 'Error: La orden de compra no ha sido editada!',
              text: 'Un producto de la orden de compra que desea editar, está quedando en stock negativo!',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          } else {
            this.$swal({
              title: 'Error!',
              text: 'Ingreso de datos fraudulento!',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        }
        this.spinner = false
      })
    },
  },
}
</script>
